<template>
    <v-content>
        <v-breadcrumbs :items="items" large></v-breadcrumbs>
        <v-container fluid>
            <!-- <v-row no-gutters>
                 <v-col cols="10"></v-col>
                 <v-col cols="2">
                     <v-btn cols="2" class="success" @click.stop="onDownload">
                         <v-icon>get_app</v-icon>
                         Export
                     </v-btn>
                 </v-col>
             </v-row>-->
            <v-expansion-panels multiple hover>
                <v-expansion-panel>
                    <v-expansion-panel-header>{{$t('filter')}}</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-container fluid>
                            <v-row col="12">
                                <v-row align="start" justify="start" class="mt-4">

                                    <v-col class="d-flex" cols="12" sm="6">
                                        <v-menu v-model="snackbar.menu1"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                :close-on-content-click="false"
                                                min-width="290px">
                                            <template v-slot:activator="{ on }">
                                                <v-text-field v-model="serverParams.fromDate"
                                                              clearable
                                                              dense
                                                              outlined
                                                              prepend-inner-icon="event"
                                                              readonly
                                                              :label="$t('from_date')"
                                                              v-on="on">
                                                </v-text-field>
                                            </template>
                                            <v-date-picker v-model="serverParams.fromDate">
                                            </v-date-picker>
                                        </v-menu>
                                    </v-col>

                                    <v-col class="d-flex" cols="12" sm="6">
                                        <v-menu v-model="snackbar.menu2"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                :close-on-content-click="false"
                                                min-width="290px">
                                            <template v-slot:activator="{ on }">
                                                <v-text-field v-model="serverParams.toDate"
                                                              clearable
                                                              dense
                                                              outlined
                                                              prepend-inner-icon="event"
                                                              readonly
                                                              :label="$t('to_date')"
                                                              v-on="on">
                                                </v-text-field>
                                            </template>
                                            <v-date-picker v-model="serverParams.toDate">
                                            </v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col class="d-flex" cols="12" sm="12">
                                        <v-spacer></v-spacer>
                                        <v-btn class="mx-2"
                                               dark
                                               small
                                               color="primary"
                                               @click="loadItems">
                                            <v-icon dark>search</v-icon>
                                            {{ $t("search") }}
                                        </v-btn>
                                        <v-spacer></v-spacer>
                                    </v-col>
                                </v-row>
                            </v-row>
                        </v-container>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <v-simple-table class="myCustomTable">
                <template v-slot:default>
                    <thead>
                        <tr>
                            <td v-for="header in columns" :key="header.field">
                                {{header.label}}
                            </td>
                        </tr>

                    </thead>
                    <tbody>
                        <template>
                            <tr v-for="(row,index) in rows" :key="index">
                                <td>{{index+1}}</td>
                                <td>{{row.startTime}}</td>
                                <td>{{row.endTime}}</td>
                                <td>{{row.totalCount}}</td>
                            </tr>
                            <template v-for="(item,count) in items.data">
                                <tr :key="'item'+count">
                                    <td v-for="(column,key) in item" :key="key">{{column}}</td>
                                </tr>
                            </template>
                        </template>

                    </tbody>
                </template>
            </v-simple-table>
            <v-snackbar v-model="snackbar.snackbar"
                        color="success"
                        :timeout="snackbar.timeout"
                        top>
                {{ callbackResponse.message }}
                <v-btn dark
                       text
                       @click="snackbar.snackbar = false">
                    Close
                </v-btn>
            </v-snackbar>
        </v-container>
    </v-content>
</template>

<script>
    import axios from "axios";

    export default {
        name: "AirportDomesticMovementList",
        props: [
            'moduleName'
        ],
        components: {},
        computed: {
            reportIsMonthWise() {
                return this.serverParams.ReportType === "monthwise";
            },
            items() {
                return [
                    {
                        text: this.$t('report'),
                        exact: true,
                        to: '/reportModules'
                    },
                    {
                        text: this.$t('immigration_api'),
                        exact: true,
                        disabled: true
                    }
                ]
            },
            columns() {
                return [
                    {
                        label: this.$t('s_no'),
                        field: 'sn',
                    },
                    {
                        label: this.$t('started_datetime'),
                        field: 'startTime',
                    },
                    {
                        label: this.$t('ended_datetime'),
                        field: 'endTime',
                    },

                    {
                        label: this.$t('total_passenger'),
                        field: 'total',
                    }
                ]
            }
        },
        methods: {
            async onDownload() {

                let param = {
                    FromDate: this.serverParams.fromDate,
                    ToDate: this.serverParams.toDate,
                };


                await axios({
                    url: 'Immigration/GetImmigrationLogAsync',
                    method: 'POST',
                    responseType: 'blob',
                    data: param
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'DOTHotel.xlsx'); //or any other extension
                    document.body.appendChild(link);
                    link.click();

                });
            },
            formResponse(data) {
                console.log({ data })
                if (data.message) {
                    this.snackbar.formModal = false
                    this.snackbar.createFormComponent = false;
                    this.dialogDelete = false;
                    this.snackbar.editFormComponent = false;
                    this.callbackResponse = data;
                    this.snackbar.snackbar = true
                    this.loadItems();
                } else {
                    this.snackbar.createFormComponent = false;
                    this.dialogDelete = false;
                    this.snackbar.editFormComponent = false;
                    this.snackbar.formModal = false
                }
            },

            dialogueClose() {
                this.historyData.editItemIndex = null
                this.snackbar.formModal = false
                this.snackbar.createFormComponent = false
                this.snackbar.editFormComponent = false
            },
            loadItems() {
                this.snackbar.isLoading = true;
                let param = {
                    FromDate: this.serverParams.fromDate,
                    ToDate: this.serverParams.toDate,
                };
                axios.post('Immigration/GetImmigrationLogAsync', param)
                    .then(response => {
                        this.rows = response.data;
                        // this.totalRecords = response.data.totalCount;
                        this.snackbar.loading = false;
                        console.log('yayyayaya', this.rows)
                    })
                    .catch(err => {
                        console.log(err)
                    });

            },

            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },

            onPageChange(params) {
                this.updateParams({ page: params.currentPage });
                this.loadItems();
            },

            onPerPageChange(params) {
                this.updateParams({ perPage: params.currentPerPage });
                this.loadItems();
            },

            onSortChange(params) {
                this.updateParams({
                    sort: [{
                        type: params[0].type,
                        field: params[0].field,
                    }],
                });
                this.loadItems();
            },

            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },

            addModal() {
                this.snackbar.formModal = true
                this.snackbar.createFormComponent = true
            },

            editItem(item) {
                this.snackbar.formModal = true
                this.snackbar.editFormComponent = true
                this.historyData.editItemIndex = item.personInformationID
            },

            deleteItem(props) {
                this.dialogDelete = true;
                this.deleteFormData.url = "Mountain/DeleteHistoryInformationByIDAsync/" + props.personInformationID;
            },
        },
        mounted() {
            this.loadItems()
            this.loadFilterData()

        },
        created() {
            //this.getProvinceList();
        },
        data() {
            return {
                AirportList: [],
                tab: 'tab-monthlyReport',
                callbackResponse: {
                    timeout: 6000
                },
                rows: [],
                ReportTypes: [
                    {
                        id: "yearwise", value: "yearwise"
                    },
                    {
                        id: "monthwise", value: "monthwise"
                    }
                ],
                dialogDelete: false,
                deleteFormData: [],
                formdata: [],
                ProvinceList: [],
                DistrictList: [],
                historyData: {
                    totalRows: 0,
                    editItemIndex: null
                },
                serverParams: {
                    search: "",
                    fromDate: "",
                    toDate: "",
                    columnFilters: {},
                    sort: [
                        {
                            field: "id",
                            type: "desc"
                        }
                    ],
                    page: 1,
                    perPage: 10,
                    ReportType: ""
                },
                snackbar: {
                    color: '',
                    snackbar: false,
                    text: '',
                    timeout: 6000,
                    isLoading: false,
                    formModal: false,
                    createFormComponent: false,
                    editFormComponent: false,
                    /* breadcrumbsItem: [
                         {
                             text: 'Dashboard',
                             disabled: false,
                             to: 'MountaineeringDashboard',
                             exact: true

                         },
                         {
                             text: 'Mountaineering History Information',
                             disabled: true,
                         },
                     ],*/
                    dynamicComponent: {
                        current: 'HistoryForm'
                    }
                }
            }
        },
    }
</script>

<style scoped lang="scss">
    $blue: #265b93;

    .myCustomTable {
        max-width: 100%;

        thead {
            background-color: $blue;
            color: white;
        }

        tbody {
            height: 100px; /* Just for the demo          */
            overflow-y: auto; /* Trigger vertical scroll    */
        }

        td, th {
            padding: 1rem
        }

        border: 1px solid #eee;

        tr, td, th {
            border: 1px solid #eee;
        }
    }

    .table-container {
        width: 100%;
    }

    .headerBackground {
        background-color: $blue;
        color: white;
    }
</style>