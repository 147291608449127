import { render, staticRenderFns } from "./ImmigrationApiList.vue?vue&type=template&id=5beb4ae1&scoped=true&"
import script from "./ImmigrationApiList.vue?vue&type=script&lang=js&"
export * from "./ImmigrationApiList.vue?vue&type=script&lang=js&"
import style0 from "./ImmigrationApiList.vue?vue&type=style&index=0&id=5beb4ae1&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5beb4ae1",
  null
  
)

export default component.exports